<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import dayjs from "dayjs";
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import DateRangeMixin from "@/mixins/DateRangeMixin";
import TaskService from "@/services/TaskService";
import DepartmentService from "@/services/DepartmentService";
import UserService from "@/services/UserService";

export default {
    name: "VActivities",
    components: { DateRangePicker },
    mixins: [DateRangeMixin],
    data() {
        return {
            loading: false,
            stats: null,
            users: [],
            deps: [],
            blockUser: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        hasData: function () {
            if (
                this.activityExt.series.length > 0 ||
                this.activityInt.series.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        activityExt: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            let cutoff = 9;

            let perc = 0;
            let acts = 0;
            let last_name = "";

            this.stats.activityBreakdown.ext.forEach((el, index) => {
                if (index < cutoff - 1) {
                    data.series.push(el.total_time_perc);
                    data.labels.push(el.activity_name);
                } else {
                    perc += parseInt(100 * el.total_time_perc);
                    acts += 1;
                    last_name = el.activity_name;
                }
            });

            if (acts > 0) {
                data.series.push(perc / 100);
                if (acts == 1) {
                    data.labels.push(last_name);
                } else {
                    data.labels.push(
                        this.$tc("reports.remaining_COUNT_activities", acts)
                    );
                }
            }

            return data;
        },
        activityInt: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            let cutoff = 8;

            let perc = 0;
            let acts = 0;
            let last_name = "";

            this.stats.activityBreakdown.int.forEach((el, index) => {
                if (index < cutoff - 1) {
                    data.series.push(el.total_time_perc);
                    data.labels.push(el.activity_name);
                } else {
                    perc += parseInt(100 * el.total_time_perc);
                    acts += 1;
                    last_name = el.activity_name;
                }
            });

            if (acts > 0) {
                data.series.push(perc / 100);
                if (acts == 1) {
                    data.labels.push(last_name);
                } else {
                    data.labels.push(
                        this.$tc("reports.remaining_COUNT_activities", acts)
                    );
                }
            }

            return data;
        },
        activityAbs: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            let cutoff = 8;

            let perc = 0;
            let acts = 0;
            let last_name = "";

            this.stats.activityBreakdown.abs.forEach((el, index) => {
                if (index < cutoff - 1) {
                    data.series.push(el.total_time_perc);
                    data.labels.push(el.activity_name);
                } else {
                    perc += parseInt(100 * el.total_time_perc);
                    acts += 1;
                    last_name = el.activity_name;
                }
            });

            if (acts > 0) {
                data.series.push(perc / 100);
                if (acts == 1) {
                    data.labels.push(last_name);
                } else {
                    data.labels.push(
                        this.$tc("reports.remaining_COUNT_activities", acts)
                    );
                }
            }

            return data;
        },
    },
    watch: {},
    async created() {
        this.loading = true;
        this.$aclGuard(this.$ACL.STATS_VIEW);
        this.resetRange(true);
        await this.fetchUsers(true);
        await this.fetchDepartments(true);
        await this.fetchData();
        this.$apiEnableWatcher();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;

                if (this.apiSearchFilter.department_id != "") {
                    this.apiSearchFilter.user_id = "";
                    this.blockUser = true;
                } else {
                    this.blockUser = false;
                }

                let params = this.$buildParams();
                const r = await TaskService.GET_activityStatistics(params);
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchData e", e);
            }

            this.loading = false;
        },
        async fetchDepartments(loading = false) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();
                this.deps = r.data.data;
            } catch (e) {
                console.log("fetchDepartments e", e);
            }

            this.loading = loading;
        },
        async fetchUsers(loading = false) {
            try {
                this.loading = true;
                const r = await UserService.dropdownAll();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers e", e);
            }
            this.loading = loading;
        },
        resetRange(set_default = false) {
            let from = null;
            let to = null;

            if (set_default) {
                from = dayjs().startOf("month").format("YYYY-MM-DD");
                to = dayjs().endOf("month").format("YYYY-MM-DD");
                console.log("SIOUREK", from, to);
            }

            this.apiSearchFilter = {
                dateRange: {
                    startDate: from,
                    endDate: to,
                },
                user_id: "",
                department_id: "",
            };
        },
    },
};
</script>

<template>
    <div class="v-reports-activities">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <!-- .top-menu -->
            <div class="row card-wrapper" v-if="true">
                <div class="col-12">
                    <div class="d-flex align-items-center flex-row mb-3">
                        <div class="v-stats-rp">
                            <date-range-picker
                                class="range-picker"
                                ref="picker"
                                opens="right"
                                :timePicker="false"
                                :showWeekNumbers="true"
                                :autoApply="true"
                                v-model="apiSearchFilter.dateRange"
                                :linkedCalendars="true"
                                :locale-data="getDateRangePickerLocale()"
                                :ranges="getDateRangePickerRanges()"
                            >
                                <template #header>
                                    <manual-range
                                        :date-range-manual="dateRangeManual"
                                        :date-range-error="dateRangeError"
                                        @exec="
                                            execManualRange(
                                                'apiSearchFilter',
                                                'dateRange'
                                            )
                                        "
                                    />
                                </template>

                                <template
                                    v-slot:input="picker"
                                    style="min-width: 350px"
                                >
                                    <template v-if="picker.startDate">
                                        {{ $d(picker.startDate, "short") }}
                                        -
                                        {{ $d(picker.endDate, "short") }}
                                    </template>
                                </template>
                            </date-range-picker>
                        </div>
                        <div class="ml-3 extra-filter">
                            <FormulateInput
                                type="select"
                                :options="deps"
                                v-model="apiSearchFilter.department_id"
                                :placeholder="
                                    $t('stats.placeholder_select_department')
                                "
                            />
                        </div>

                        <div class="ml-3 extra-filter">
                            <FormulateInput
                                type="select"
                                :options="users"
                                v-model="apiSearchFilter.user_id"
                                :placeholder="
                                    $t('stats.placeholder_select_user')
                                "
                                :disabled="blockUser"
                            />
                        </div>

                        <div
                            class="text-muted small m-click m-link ml-3"
                            @click="resetRange"
                        >
                            <i class="far fa-sync"></i>
                            {{ $t("stats.reset") }}
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="loading">
                    <v-loader :loading="true" />
                </div>

                <div class="col-12" v-else-if="!hasData">
                    <div class="alert alert-warning alert-outline">
                        {{ $t("inv_stats.no_data_found_try_different_period") }}
                    </div>
                </div>
            </div>
            <div class="row card-wrapper" v-if="hasData">
                <div class="col-12">
                    <h4>{{ $t("report.global_time_usage") }}</h4>

                    <card body-classes="px-4">
                        <div class="row">
                            <div class="col mini-belt">
                                <h5>
                                    {{ $t("report.customer_related_time") }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.ext_total
                                            | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $n(
                                            stats.activityBreakdown
                                                .ext_total_perc
                                        )
                                    }}%
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>{{ $t("report.internal_time") }}</h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.int_total
                                            | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $n(
                                            stats.activityBreakdown
                                                .int_total_perc
                                        )
                                    }}%
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>{{ $t("report.absence_time") }}</h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.abs_total
                                            | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $n(
                                            stats.activityBreakdown
                                                .abs_total_perc
                                        )
                                    }}%
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>{{ $t("report.total_time") }}</h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.total_sum
                                            | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">100%</div>
                            </div>
                        </div>
                    </card>
                </div>
                <div
                    class="col-12"
                    v-if="'personalStat' in stats.activityBreakdown"
                >
                    <h4>{{ $t("report.detailed_personal_statistics") }}</h4>
                    <card body-classes="px-4">
                        <div class="row">
                            <div class="col mini-belt">
                                <h5>
                                    {{
                                        $t("report.personal_expected_worktime")
                                    }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.personalStat
                                            .expectedTime | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $d(
                                            new Date(
                                                stats.activityBreakdown.personalStat.dateFrom
                                            ),
                                            "short"
                                        )
                                    }}
                                    -
                                    {{
                                        $d(
                                            new Date(
                                                stats.activityBreakdown.personalStat.dateTo
                                            ),
                                            "short"
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>
                                    {{ $t("report.personal_balance") }}
                                </h5>
                                <div class="mini-belt__value">
                                    <i
                                        v-if="
                                            stats.activityBreakdown.personalStat
                                                .balance != 0
                                        "
                                        class="far"
                                        :class="{
                                            'fa-plus':
                                                stats.activityBreakdown
                                                    .personalStat.balance > 0,
                                            'fa-minus':
                                                stats.activityBreakdown
                                                    .personalStat.balance < 0,
                                        }"
                                    >
                                    </i>

                                    <template
                                        v-else-if="
                                            stats.activityBreakdown.personalStat
                                                .balance > 0
                                        "
                                    >
                                    </template>

                                    {{
                                        stats.activityBreakdown.personalStat
                                            .balance | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $d(
                                            new Date(
                                                stats.activityBreakdown.personalStat.dateFrom
                                            ),
                                            "short"
                                        )
                                    }}
                                    -
                                    {{
                                        $d(
                                            new Date(
                                                stats.activityBreakdown.personalStat.dateTo
                                            ),
                                            "short"
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>
                                    {{ $t("report.personal_daily_goal") }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.personalStat
                                            .dailyGoal | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc"></div>
                            </div>
                            <div class="col mini-belt">
                                <h5>
                                    {{ $t("report.personal_avg_per_day") }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.personalStat
                                            .averageDaily | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $tc(
                                            "report.#count_workdays_in_period",
                                            stats.activityBreakdown.personalStat
                                                .workdays
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </card>
                </div>

                <div class="col-12" v-if="false">
                    <h4>{{ $t("report.customer_related_time_details") }}</h4>
                    <card body-classes="px-4">
                        <div class="row">
                            <div class="col mini-belt">
                                <h5>
                                    {{
                                        $t(
                                            "report.customer_related_billable_time"
                                        )
                                    }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.ext_diff
                                            .total_billable | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $n(
                                            stats.activityBreakdown.ext_diff
                                                .billable_perc
                                        )
                                    }}%
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>
                                    {{
                                        $t(
                                            "report.customer_related_non_billable_time"
                                        )
                                    }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        stats.activityBreakdown.ext_diff
                                            .total_non_billable | toTime
                                    }}
                                </div>
                                <div class="small mini-belt__perc">
                                    {{
                                        $n(
                                            stats.activityBreakdown.ext_diff
                                                .non_billable_perc
                                        )
                                    }}%
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>
                                    {{
                                        $t("report.billable_global_percentage")
                                    }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        $n(
                                            stats.activityBreakdown.ext_diff
                                                .global_billable_perc
                                        )
                                    }}%
                                </div>
                            </div>
                            <div class="col mini-belt">
                                <h5>
                                    {{
                                        $t(
                                            "report.non_billable_global_percentage"
                                        )
                                    }}
                                </h5>
                                <div class="mini-belt__value">
                                    {{
                                        $n(
                                            stats.activityBreakdown.ext_diff
                                                .global_non_billable_perc
                                        )
                                    }}%
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->
            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.customer_activities") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: activityExt.labels,

                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="activityExt.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.internal_activities") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: activityInt.labels,

                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="activityInt.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->
            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.all_customer_activities") }}
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.activity") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>

                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.activityBreakdown
                                    .ext"
                                :key="index"
                            >
                                <v-td>{{ u.activity_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>

                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.all_internal_activities") }}
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.activity") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>

                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.activityBreakdown
                                    .int"
                                :key="index"
                            >
                                <v-td>{{ u.activity_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>

                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                    </card>
                </div>
            </div>
            <!-- New stat row -->
            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.absence") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: activityAbs.labels,

                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="activityAbs.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
                <!-- rioght pane -->
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.all_absence") }}
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.activity") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>

                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.activityBreakdown
                                    .abs"
                                :key="index"
                            >
                                <v-td>{{ u.activity_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>

                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                    </card>
                </div>
            </div>
        </div>
        <!-- .container-fluid -->
    </div>
</template>

<style lang="scss">
.v-stats-rp {
    flex: 0 0 300px;
}

.extra-filter {
    .form-group {
        margin-bottom: 0px !important;
    }
}

.mini-belt {
    text-align: center;
    h5 {
        margin-bottom: 2px;
    }

    &__value {
        font-weight: bold;
    }

    &__perc {
        margin-top: 1px;
    }
}
</style>
